import React, { useState, useEffect, useCallback } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { MSALTokenCredential } from "./MSALTokenCredential";
import ConfigEditor from "./ConfigEditor";
import { generateConfigFromOnnxFile } from "./ModelParser";
import { getLoadModelUrl, getUnLoadModelUrl } from "./requestAIServer";

const ModelManager = () => {
  const { instance, accounts } = useMsal();
  const [modelList, setModelList] = useState([]);
  const [modelFile, setModelFile] = useState(null);
  const [customOperationFile, setCustomOperationFile] = useState(null);
  const [blobServiceClient, setBlobServiceClient] = useState(null);
  const [isConfigCorrect, setIsConfigCorrect] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [config, setConfig] = useState("");
  const [modelName, setModelName] = useState("None");
  const directoryScheme = `
${modelName}
├── 1
${customOperationFile ? "│   ├── model.onnx\n│   └── custom_op.so" : "│   └── model.onnx"}
└── config.pbtxt
`;

  const accountName = "staimodelshrkrc";
  const containerName = "dev";
  const account = accounts[0];
  const userId = account?.username.split("@")[0].replace('.', '-');

  const listModels = useCallback(async () => {
    if (!blobServiceClient) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const models = new Set();

    try {
      for await (const blob of containerClient.listBlobsFlat()) {
        if (!blob.name.includes(userId)) {
          continue;
        }

        const model = blob.name.split("/")[0];
        models.add(model);
      }

      setModelList(Array.from(models));
    } catch (error) {
      console.error("Error listing blobs:", error.message);
    }
  }, [blobServiceClient, userId]);

  const uploadModel = async () => {
    if (!modelFile || !blobServiceClient || !isConfigCorrect) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);

    try {
      setUploadProgress(0);

      const configFileName = `${modelName}/config.pbtxt`;
      const configBlobClient =
        containerClient.getBlockBlobClient(configFileName);
      await configBlobClient.upload(config, config.length, {
        onProgress: (progress) => {
          const percentage = Math.round(
            (progress.loadedBytes / modelFile.size) * 100,
          );
          setUploadProgress(percentage);
        },
      });

      setUploadProgress(100);

      if (customOperationFile) {
        setUploadProgress(0);

        const customOperationFileName = `${modelName}/1/custom_op.so`;
        const customOperationBlobClient =
          containerClient.getBlockBlobClient(customOperationFileName);
        await customOperationBlobClient.uploadBrowserData(customOperationFile, {
          onProgress: (progress) => {
            const percentage = Math.round(
              (progress.loadedBytes / customOperationFile.size) * 100,
            );
            setUploadProgress(percentage);
          },
        });

        setUploadProgress(100);
      }

      setUploadProgress(0);

      const modelFileName = `${modelName}/1/model.onnx`;
      const modelBlobClient = containerClient.getBlockBlobClient(modelFileName);
      await modelBlobClient.uploadBrowserData(modelFile, {
        onProgress: (progress) => {
          const percentage = Math.round(
            (progress.loadedBytes / modelFile.size) * 100,
          );
          setUploadProgress(percentage);
        },
      });

      setUploadProgress(100);

      listModels();
    } catch (error) {
      console.error("Error uploading blob:", error.message);
    }
  };

  const deleteModel = async (modelName) => {
    if (!blobServiceClient) return;

    const containerClient = blobServiceClient.getContainerClient(containerName);

    try {
      for await (const blob of containerClient.listBlobsFlat()) {
        if (!blob.name.includes(modelName)) {
          continue;
        }
        await containerClient.deleteBlob(blob.name);
      }
      listModels();
    } catch (error) {
      console.error("Error deleting blob:", error.message);
    }
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account,
    };

    instance
      .acquireTokenSilent(request)
      .then(() => {
        const tokenCredential = new MSALTokenCredential(instance, account, [
          "https://storage.azure.com/.default",
        ]);

        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net`,
          tokenCredential,
        );
        setBlobServiceClient(blobServiceClient);
      })
      .catch((error) => {
        console.error("Token acquisition failed:", error);
      });
  }, [instance, account]);

  useEffect(() => {
    listModels();
  }, [listModels]);

  useEffect(() => {
    const parseONNXFile = async () => {
      try {
        if (!modelFile) {
          return;
        }

        const initialConfig = await generateConfigFromOnnxFile(modelFile, userId, !!customOperationFile);
        setConfig(initialConfig);
        setModelName(initialConfig.split('"')[1]);
      } catch (error) {
        console.error("Error parsing ONNX file:", error);
      }
    };

    parseONNXFile();
  }, [modelFile, customOperationFile]);

  const getLinuxLoadCommand = (model) =>
    `curl -X POST -v ${getLoadModelUrl(model)}`;
  const getWindowsLoadCommand = (model) =>
    `curl -Method POST -v ${getLoadModelUrl(model)}`;
  const getLinuxUnloadCommand = (model) =>
    `curl -X POST -v ${getUnLoadModelUrl(model)}`;
  const getWindowsUnloadCommand = (model) =>
    `curl -Method POST -v ${getUnLoadModelUrl(model)}`;

  return (
    <div>
      <h2>Model Manager</h2>
      <button onClick={listModels}>Refresh Models Owned by Me</button>
      <ul>
        {modelList.map((model) => (
          <li key={model}>
            {model}
            <button onClick={() => deleteModel(model)}>Delete</button>
            <br />
            <p>To load this model, use curl command below (click to copy)</p>
            <code
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(getLinuxLoadCommand(model));
              }}
            >{`(Linux) ${getLinuxLoadCommand(model)}`}</code>
            <br />
            <br />
            <code
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(getWindowsLoadCommand(model));
              }}
            >{`(Windows) ${getWindowsLoadCommand(model)}`}</code>
            <p>To unload this model, use curl command below (click to copy)</p>
            <code
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(getLinuxUnloadCommand(model));
              }}
            >{`(Linux) ${getLinuxUnloadCommand(model)}`}</code>
            <br />
            <br />
            <code
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(getWindowsUnloadCommand(model));
              }}
            >{`(Windows) ${getWindowsUnloadCommand(model)}`}</code>
          </li>
        ))}
      </ul>
      <p> Model </p>
      <input
        type="file"
        accept=".onnx"
        onChange={(e) => setModelFile(e.target.files[0])}
      />
      <p> (Optional) Custom Operation </p>
      <input
        type="file"
        accept=".so"
        onChange={(e) => setCustomOperationFile(e.target.files[0])}
      />
      <br />
      <br />
      <ConfigEditor
        userId={userId}
        config={config}
        customOperationExists={!!customOperationFile}
        modelName={modelName}
        setConfig={setConfig}
        setIsConfigCorrect={setIsConfigCorrect}
        setModelName={setModelName}
      />
      {config && (
        <div>
          <pre>{directoryScheme}</pre>
        </div>
      )}
      <div>
        {!isConfigCorrect && modelFile && (
          <div>
            <pre>Incorrect configuration!</pre>
            <pre>Fix configuration in Config Editor to upload</pre>
          </div>
        )}
        {isConfigCorrect && modelFile && (
          <button onClick={uploadModel}>Upload</button>
        )}
        {uploadProgress > 0 && (
          <div>
            <p>Upload Progress: {uploadProgress}%</p>
            <progress value={uploadProgress} max="100"></progress>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelManager;
